// extracted by mini-css-extract-plugin
export var sprite3DIcons = "ct_pR";
export var sprite3DIcons__sprite = "ct_pS";
export var sprite3DIcons__sprite3DIcon_1 = "ct_pT";
export var sprite3DIcons__sprite3DIcon_10 = "ct_p3";
export var sprite3DIcons__sprite3DIcon_11 = "ct_p4";
export var sprite3DIcons__sprite3DIcon_12 = "ct_p5";
export var sprite3DIcons__sprite3DIcon_13 = "ct_p6";
export var sprite3DIcons__sprite3DIcon_14 = "ct_p7";
export var sprite3DIcons__sprite3DIcon_15 = "ct_p8";
export var sprite3DIcons__sprite3DIcon_16 = "ct_p9";
export var sprite3DIcons__sprite3DIcon_17 = "ct_qb";
export var sprite3DIcons__sprite3DIcon_18 = "ct_qc";
export var sprite3DIcons__sprite3DIcon_19 = "ct_qd";
export var sprite3DIcons__sprite3DIcon_2 = "ct_pV";
export var sprite3DIcons__sprite3DIcon_20 = "ct_qf";
export var sprite3DIcons__sprite3DIcon_21 = "ct_qg";
export var sprite3DIcons__sprite3DIcon_22 = "ct_qh";
export var sprite3DIcons__sprite3DIcon_3 = "ct_pW";
export var sprite3DIcons__sprite3DIcon_4 = "ct_pX";
export var sprite3DIcons__sprite3DIcon_5 = "ct_pY";
export var sprite3DIcons__sprite3DIcon_6 = "ct_pZ";
export var sprite3DIcons__sprite3DIcon_7 = "ct_p0";
export var sprite3DIcons__sprite3DIcon_8 = "ct_p1";
export var sprite3DIcons__sprite3DIcon_9 = "ct_p2";