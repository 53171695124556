// extracted by mini-css-extract-plugin
export var aiAwardsSection = "T_h8";
export var aiBenefitsSection = "T_h2";
export var aiBusinessSection = "T_h5";
export var aiCasesSection = "T_h6";
export var aiCompanySection = "T_h7";
export var aiIndustriesSection = "T_h1";
export var aiPracticesSection = "T_h3";
export var aiPrimeSection = "T_hZ";
export var aiProcessSection = "T_h4";
export var aiQuoteSection = "T_h9";
export var aiServicesIconSection = "T_h0";